import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Header from './Header';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import About from './pages/About';
import Home from './App';

ReactDOM.render(
  <React.StrictMode>
    {/* <Header /> */}
    {/* <App /> */}
    <Router>
        <Header/>
        <Switch>
          <Route path exact = '/app' component = {Home}/>
          <Route path = '/about' component = {About}/>
        </Switch>
        {/* <Footer/> */}
      </Router>

    <Footer/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
