import logo from './sustainDigitalLogo.png';
import './App.css';
import { BrowserRouter as Router,Route,Switch, Link,NavLink } from 'react-router-dom';

function Header() {
  return (
    <div className="Header">
      <header className="Site-header">
        <div className="Header-header">
          <img src={logo} className="Site-logo" alt="logo" />
          <ul className="Header-menu">
            {/* <li>
             <NavLink exact={true} activeClassName='is-active' className="nav-link" to="/about">About</NavLink>
            </li> */}
            {/* <li>
             <NavLink exact={true} activeClassName='is-active' className="nav-link" to="/">Home</NavLink>
            </li> */}
            <li>
             Call: +255 658 031 993
            </li>
            <li>
             Email: jambo@sustaindigital.co.tz
            </li>
            
            
          </ul>
 
          
        </div>
      </header>
    </div>
  );
}

export default Header;
