import './App.css';




function Footer() {
  let newDate = new Date();
  let _date = newDate.getFullYear();
  // alert(_date)
  return (
    <div >
      <div className="Footer">
        <div className="Footer-section">
          <p>
          </p>
        </div>
        <div className="Footer-section">
        <p>
          </p>
        </div>
        <div className="Footer-section">
          <p>
          </p>
        </div>
      </div>
      <div className='BottomFooter'>
        © {_date} Sustain Digital Tanzania
      </div>

    </div>


  );
}

export default Footer;
