
import './App.css';
import ReactTypingEffect from 'react-typing-effect';
import live_stream from './live_stream.jpg';
import live_stream2 from './live_stream_2.jpg';
import individual from './gradu.jpg';
import corporates from './corporate.jpg';

const list = ['Reactjs Typing', 'custom speed']

function App() {

  return (
    <div className="App">
   
      <header className="App-home" id="Content">
        <div className="Home-image" style={{marginTop:'-4rem' }}>
          <h3>True image & Fine art that craft compelling <b id="red">corporate Profile</b></h3>


        </div>
        <div className="Home-section-wd">
          <div className="Home-section" id="object3">

            <h3>LIVE STREAMING</h3>
            <p>Let your audience feel your presense on the go.</p>
            <img src={live_stream} width="100%" alt="logo" />
          </div>
          <div className="Home-section" id="object4">
            <h3>EVENTS</h3>
            <p>We make life beautiful with the eye of Camera.</p>
            <img src={live_stream2} width="100%" alt="logo" />
          </div>
        </div>
        <div className="Home-section-wd">
          <div className="Home-section" id="object3">
            <h3>CORPORATES</h3>
            <p>Your corporate functins need an art of vision.</p>
            <img src={corporates} width="100%" alt="logo" />
          </div>
          <div className="Home-section" id="object4">
            <h3>INDIVIDUAL</h3>
            <p>People change, time change, but memories remain.</p>
              <img src={individual} width="100%" alt="logo" />
          </div>
        </div>
        
      </header> 
    </div>
  );
}

export default App;
